import React, { useState, useEffect } from 'react';
import './Maquina.css';

const frases = [
  "Olá, Visitante! Seja Bem Vindo!",
  "Sou estudante de Tecnologia da informação.","Python Javascript",
  "Estudo análise de dados.", " E gosto de desenhar mapas"," criar Sites e",
  "também desenhos Gráficos em Power BI e Python"," "
];

const MaquinaEscrever = () => {
  const [fraseAtual, setFraseAtual] = useState(0);
  const [textosAnteriores, setTextosAnteriores] = useState([]);
  const [textoAtual, setTextoAtual] = useState('');

  useEffect(() => {
    const frase = frases[fraseAtual];
    let texto = '';

    const escreverTexto = setInterval(() => {
      texto += frase.charAt(texto.length);
      setTextoAtual(texto);
      if (texto === frase) {
        clearInterval(escreverTexto);
        setTextosAnteriores(prevTextos => [...prevTextos, texto]);
        setTimeout(() => {
          if (fraseAtual < frases.length - 1) {
            setFraseAtual(fraseAtual + 1);
            setTextoAtual('');
          }
        }, -2000); // Intervalo de 1 segundo antes de mostrar a próxima frase
      }
    }, 200); // intervalo de 200ms para escrever letra por letra

    return () => clearInterval(escreverTexto);
  }, [fraseAtual]);

  return (
    <div className="maquina-escrever">
      {textosAnteriores.map((texto, index) => (
        <div key={index} className="texto-anterior">
          {texto}
        </div>
      ))}
      <div className="texto-atual">
        {textoAtual}
      </div>
    </div>
  );
};

export default MaquinaEscrever;
