import React, { useRef, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import './App.css';
import Raul from './imagens/Raul.png';
import Botton from './Botton';
import react from './imagens/react.png';
import js from './imagens/js.png';
import Menu from './Menu';
import Mapa from './Mapa das escolas.js';
import Aldeias from './page/Aldeias.js';
import Roubos from './page/Roubos.js';
import Semterra from './Semterra.js';
import Jogo from './page/Jogo';
import Galeria from './page/Galeria';
import Space from './page/Space';
import Ponto from './PontoVendas.js';
import Maquina from './page/Maquina.jsx';
import Filmes from './page/filmes.js';
import Bottoninsta from "./bottoninsta.jsx";
import verificarTamanhoDaTela from './verificartela.jsx';
import Disco from "./imagens/disco.png";

const Home = () => {
  const boxRef = useRef(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const [contagemAcessos, setContagemAcessos] = useState(0);

  useEffect(() => {
    const handleAnimationEnd = () => {
      const currentBoxRef = boxRef.current;
      if (currentBoxRef) {
        currentBoxRef.classList.add('hideImage');
      }
    };

    if (boxRef.current) {
      boxRef.current.addEventListener('animationend', handleAnimationEnd);
    }

    return () => {
      const currentBoxRef = boxRef.current;
      if (currentBoxRef) {
        currentBoxRef.removeEventListener('animationend', handleAnimationEnd);
      }
    };
  }, []);

  useEffect(() => {
    verificarTamanhoDaTela();
    window.addEventListener('resize', verificarTamanhoDaTela);
    return () => {
      window.removeEventListener('resize', verificarTamanhoDaTela);
    };
  }, []);

  useEffect(() => {
    // Recupera a contagem de acessos do localStorage
    const acessoString = localStorage.getItem('contagemAcessos');
    const acessoCount = parseInt(acessoString) || 0;
    setContagemAcessos(acessoCount);
  }, []);

  useEffect(() => {
    // Incrementa a contagem de acessos
    localStorage.setItem('contagemAcessos', contagemAcessos + 1);
  }, [contagemAcessos]);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <div className="App">
      <Menu visible={menuVisible} />
      <div>
        <img className='reactt' src={react} alt='logo' />
        <img className='reactt' src={js} alt='logo' />
      </div>

      <div className="box" ref={boxRef}>
        <img src={Raul} alt='Raul' />
      </div>
      <div >
        <img className='indisk' src={Disco} alt="discoo" />
      </div>

      <section className='maquina'>
        <Maquina />
      </section>

      <Outlet />

      <div className='sec01'>
        <Botton />
      </div>
      <div className='sec02'>
        <Bottoninsta/>
      </div>

      <p className='contagem'>Visitante Numero: {contagemAcessos}</p>
    </div>
  );
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/jogo" element={<Jogo />} />
        <Route path="/galeria" element={<Galeria />} />
        <Route path="/space" element={<Space />} />
        <Route path="/Mapa" element={<Mapa />} />
        <Route path="/PontoVendas" element={<Ponto />} />
        <Route path="/Aldeias" element={<Aldeias />} />
        <Route path="/Roubos" element={<Roubos />} />
        <Route path="/Semterra" element={<Semterra />} />
        <Route path="/Filmes" element={<Filmes />} />
      </Routes>
    </Router>
  );
}

export default App;
