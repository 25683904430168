import React, { useEffect, useState } from 'react';
import Menu from '../Menu';
import verificarTamanhoDaTela from '../verificartela';

function Filmes() {
  useEffect(() => {
    verificarTamanhoDaTela();
    window.addEventListener('resize', verificarTamanhoDaTela);
    return () => {
      window.removeEventListener('resize', verificarTamanhoDaTela);
    };
  }, []);

  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <div>
      <Menu visible={menuVisible} />
      <h1>Feito com JAVASCRIPT</h1>
      
      <iframe
        title="Filmes IMDB"
        src="https://www.bessamsservicos.com.br/"
        width="100vh"
        height="100vh"
        frameBorder="0"
        scrolling="auto"
        allowFullScreen
        style={{ border: 'none', margin: 0, padding: 0, width: '100%', height: '100vh' }}
      />
    </div>
  );
}

export default Filmes;
