import React, { useEffect } from 'react';
import Menu from '../Menu';
import '../page/Jogo.css';
import Raul from '../imagens/Raul.png'; // Importe a imagem diretamente
import Imgs1 from '../imagens/imgs (1).jpeg'; // Importe a imagem diretamente
import Imgs2 from '../imagens/imgs (2).jpeg';
import Imgs3 from '../imagens/imgs (3).jpeg';
import Imgs4 from '../imagens/imgs (4).jpeg';
import Imgs5 from '../imagens/imgs (5).jpeg';
import Imgs6 from '../imagens/imgs (6).jpeg';
import Imgs7 from '../imagens/imgs (7).jpeg';
import Imgs8 from '../imagens/imgs (8).jpeg';
import Imgs9 from '../imagens/imgs (9).jpeg';
import verificarTamanhoDaTela from '../verificartela';

const Jogo = () => {
  useEffect(() => {
    verificarTamanhoDaTela(); // Verifica o tamanho da tela quando a página é carregada

    // Verifica o tamanho da tela sempre que ela for redimensionada
    window.addEventListener('resize', verificarTamanhoDaTela);

    // Remove o listener do evento resize ao desmontar o componente
    return () => {
      window.removeEventListener('resize', verificarTamanhoDaTela);
    };
  }, []);

  // Utilize a importação direta da imagem
  const images = [
    Raul,
    Imgs1,
    Imgs2,
    Imgs3,
    Imgs4,
    Imgs5,
    Imgs6,
    Imgs7,
    Imgs8,
    Imgs9,
  ];

  return (
    <div className="mod01">
      <Menu />
      <h1>Alguns Trabalhos</h1>
      <div className="mod-container">
        <div className="mod-column">
          {images.map((image, index) => (
            <div className="mod-card" key={index}>
              <img src={image} alt="Imagem do Card" />
            </div>
          ))}
        
        </div>
      </div>
    </div>
  );
};

export default Jogo;
