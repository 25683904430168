import React, { useEffect } from 'react';
import Menu from '../Menu';
import verificarTamanhoDaTela from '../verificartela';

function Roubos() {
  useEffect(() => {
    verificarTamanhoDaTela(); // Verifica o tamanho da tela quando a página é carregada

    // Verifica o tamanho da tela sempre que ela for redimensionada
    window.addEventListener('resize', verificarTamanhoDaTela);

    // Remove o listener do evento resize ao desmontar o componente
    return () => {
      window.removeEventListener('resize', verificarTamanhoDaTela);
    };
  }, []);

  return (
    <div>
      <Menu />
      <h1>Roubos de Celulares em SP 2019</h1>
      
      {/* Incorporando o mapa interativo */}
      <iframe 
        title="Roubos de telefones SP"
        src="https://raul-cordeiro.github.io/roubosSP/"
        width="100%" 
        height="100vh"
        frameBorder="0" 
        scrolling="no"
        allowFullScreen={true} 
        style={{ border: "none", margin: 0, padding: 0, width: "100%", height: "100vh" }}
      />
    </div>
  );
}

export default Roubos;
