import React, { useEffect } from 'react'; // Importando useEffect
import './Galeria.css';
import Menu from '../Menu';
import { Link } from 'react-router-dom';
import Disco from '../imagens/disco.png';
import verificarTamanhoDaTela from '../verificartela'; // Importando a função de verificação do tamanho da tela

const Galery = () => {
  // Adicionando useEffect para verificar o tamanho da tela
  useEffect(() => {
    verificarTamanhoDaTela(); // Verifica o tamanho da tela quando a página é carregada

    // Verifica o tamanho da tela sempre que ela for redimensionada
    window.addEventListener('resize', verificarTamanhoDaTela);

    // Remove o listener do evento resize ao desmontar o componente
    return () => {
      window.removeEventListener('resize', verificarTamanhoDaTela);
    };
  }, []);

  return (
    <div className="app-container">
      <Menu />
      <h1>Nossos Trabalhos</h1>
      <div className="cards-container">
        <Link to="https://raulcordeiro.shop">
          <div className="card">
            <p>PDV</p>
          </div>
        </Link>

        <Link to="https://www.youtube.com/channel/UCFRJ_wxzozAOIy0vHSGQuDQ">
          <div className="card2">
            <p>Videos</p>
          </div>
        </Link>

        <Link to="https://minhaagendaraul.glideapp.io">
          <div className="card3">
            <p>Aplicativos</p>
          </div>
        </Link>

        <Link to="https://www.flickr.com/photos/103285528@N06/">
          <div className="card4">
            <p>Artes</p>
          </div>
        </Link>
      </div>

      <section className="section">
        <div>
          <img src={Disco} className='verde' alt="Imagem Disco" /> {/* Adicionando o atributo 'alt' */}
          <Link to="https://wa.me/5562984687974" className="Link"><p>WhatsApp</p></Link>
        </div>
      </section>
    </div>
  );
};

export default Galery;
