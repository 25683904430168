import React, { useState, useEffect } from 'react';
import './Space.css';
import Menu from '../Menu';
import image1 from '../imagens/pdv1.png';
import image5 from '../imagens/pdv (1).png';
import image6 from '../imagens/pdv (2).png';
import image7 from '../imagens/pdv (3).png';
import image4 from '../imagens/pdv (4).png';
import image3 from '../imagens/pdv (5).png';
import image2 from '../imagens/pdv (6).png';

const Slides = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [
    image1,
    image5,
    image6,
    image7,
    image4,
    image3,
    image2,
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage(prevImage => (prevImage === images.length - 1 ? 0 : prevImage + 1));
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div>
      <Menu />
      <div>
        <h1>Imagens do EndPoint_PDV</h1>
      </div>
      <div className="slideshow">
        <img src={images[currentImage]} alt={`Slide ${currentImage}`} />
      </div>
    </div>
  );
};

export default Slides;
