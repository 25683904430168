import React from 'react';
import './botonn.css';

function Button() {
  // Defina o link do WhatsApp para o atributo 'href'.
  const whatsappLink = 'https://api.whatsapp.com/send?phone=5562984687974';

  return (
    <div>
      {/* Utilize a classe 'boton' para aplicar os estilos ao botão. */}
      {/* O link para o WhatsApp é definido no atributo 'href'. */}
      <a href={whatsappLink} className="boton" target="_blank" rel="noopener noreferrer">
        WhatsApp
      </a>
    </div>
  );
}

export default Button;