import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Menu.css';

const Menu = () => {
  const [menuVisible, setMenuVisible] = useState(false); // Estado para controlar a visibilidade do menu

  const toggleMenu = () => {
    setMenuVisible(!menuVisible); // Inverte o estado de visibilidade do menu
  };

  return (
    <div>
      <button id="toggle-menu" onClick={toggleMenu}>Abrir Menu</button>
      <div className={`menu ${menuVisible ? 'show' : ''}`}>
        <Link to="/" onClick={toggleMenu}>Home</Link>
        <Link to="/jogo" onClick={toggleMenu}>Artes</Link>
        <Link to="/galeria" onClick={toggleMenu}>Trabalhos</Link>
        <Link to="/space" onClick={toggleMenu}>Fotos</Link>
        <Link to="/pontovendas" onClick={toggleMenu}>Ponto de Vendas</Link>
        <Link to="/Mapa" onClick={toggleMenu}>Mapa das Escolas</Link>
        <Link to="/Aldeias" onClick={toggleMenu}>Aldeias</Link>
        <Link to="/Roubos" onClick={toggleMenu}>Roubos_SP</Link>
        <Link to="/Semterra" onClick={toggleMenu}>Sem Terra</Link>
        <Link to="/Filmes" onClick={toggleMenu}>Filmes</Link>
        
      </div>
    </div>
  );
};

export default Menu;
