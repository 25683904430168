import React, { useEffect, useState } from 'react';
import Menu from './Menu';
import verificarTamanhoDaTela from './verificartela';

function Mapa() {


  useEffect(() => {
    verificarTamanhoDaTela();
    window.addEventListener('resize', verificarTamanhoDaTela);
    return () => {
      window.removeEventListener('resize', verificarTamanhoDaTela);
    };
  }, []);

  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <div>
      <Menu visible={menuVisible} />
      <h1>Feito com Python</h1>
      
      <iframe
        title="Mapa das Escolas"
        src="https://raul-cordeiro.github.io/mapa-das-escolas/"
        width="100%"
        height="100vh"
        frameBorder="0"
        scrolling="no"
        allowFullScreen
        style={{ border: 'none', margin: 0, padding: 0, width: '100%', height: '100vh' }}
      />
    </div>
  );
}

export default Mapa;
