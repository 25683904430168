// Função para verificar o tamanho da tela e exibir o botão de menu, se necessário
function verificarTamanhoDaTela() {
    const toggleMenuElement = document.getElementById('toggle-menu');
    if (toggleMenuElement) {
        // Verifica se a largura da tela é menor que 360 pixels
        if (window.innerWidth < 460) {
            // Se for menor que 360 pixels, mostra o botão de menu
            toggleMenuElement.style.display = 'block';
        } else {
            // Se não, esconde o botão de menu
            toggleMenuElement.style.display = 'none';
        }
    }
}

// Chama a função quando a página é carregada
window.addEventListener('load', verificarTamanhoDaTela);

// Chama a função sempre que o tamanho da janela for alterado (por exemplo, quando redimensionada)
window.addEventListener('resize', verificarTamanhoDaTela);

// Exporta a função para que possa ser importada em outros arquivos
export default verificarTamanhoDaTela;
