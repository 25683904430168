import React from 'react';
import './Bottoninsta.css';

function Bottoninsta() {
  // Defina o link do WhatsApp para o atributo 'href'.
  const whatsappLink = 'https://instagram.com/euraulcordeiro';

  return (
    <div>
      {/* Utilize a classe 'boton' para aplicar os estilos ao botão. */}
      {/* O link para o WhatsApp é definido no atributo 'href'. */}
      <a href={whatsappLink} className="botonn" target="_blank" rel="noopener noreferrer">
        Instagram
      </a>
    </div>
  );
}

export default Bottoninsta;